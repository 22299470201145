import Modal from "../../components/modal";
import React from "react";

const AheadModal = () => (
  <Modal>
    <h3 className="leads-the-modal">
      ReleasePage <span className="muted">Better release communication</span>
    </h3>
    <ul className="tags">
      <li>Node.js</li>
      <li>React</li>
      <li>Redux</li>
      <li>MongoDB</li>
      <li>Redis</li>
    </ul>
    <p>
      During our careers we have built numerous SaaS products, and a big pain
      point we both encountered was how to notify users when there was a new
      software release, and how to provide the list of changes in the new
      version. GitHub helpfully provides us with 'GitHub Releases', allowing us
      to track software changes easily, however this is decidedly developer
      focussed.
    </p>
    <p>
      After communicating with startups and small businesses we set out to solve
      this issue by embarking on another passion project venture. We
      architected, designed, and built{" "}
      <a href="https://releasepage.co">ReleasePage</a> - a
      changelog-as-a-service which allows users to create branded, totally
      customised, beautiful web pages for their releases.
    </p>
    <p>
      We solved a core problem, the need to duplicate release notes, by syncing
      them in real time as soon as they’re published to GitHub. Users can create
      as many Release Pages as they need for their projects, and even combine
      multiple projects into one Release Page - keeping everything together for
      their users.
    </p>
    <p>
      By tracking and storing live release information we are able to make
      several other improvements to the user-experience around releasing
      software via a couple of other little passion projects. Using Reload.js
      all users within a web app can be notified when a new version is deployed
      and are given the option to refresh the page, and Version.js provides
      pretty release version badges so that users always know which version is
      the latest.
    </p>
  </Modal>
);

export default AheadModal;
